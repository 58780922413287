@import "./Constants.scss";
@import "./Reset.scss";

html {
  @media screen and (min-width: $lg) {
    font-size: 10px;
  }

  @media screen and (min-width: $sm) and (max-width: $lg) {
    font-size: 8px;
  }

  @media screen and (max-width: $sm) {
    font-size: 7px;
  }
}

@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("./fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway SemiBold";
  src: url("./fonts/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Medium";
  src: url("./fonts/Raleway-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Regular";
  src: url("./fonts/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Anton Regular";
  src: url("./fonts/Anton-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat ExtraBold";
  src: url("./fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Medium";
  src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Italic";
  src: url("./fonts/Montserrat-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Alte Haas Grotesk Regular";
  src: url("./fonts/AlteHaasGroteskRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Alte Haas Grotesk Bold";
  src: url("./fonts/AlteHaasGroteskBold.ttf") format("truetype");
}
h1 {
  font-family: "Anton Regular" ;
  font-weight: 700;
  font-size: 7.2rem;
  line-height: 7.2rem;
  margin-top: 0;
  margin-bottom: 0;
}

h2 {
  font-family: "Alte Haas Grotesk Regular" ;
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-top: 0;
  margin-bottom: 0;
  
}

h3 {
  font-family: "Raleway Medium" ;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  font-family: "Montserrat ExtraBold" ;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-top: 0;
  margin-bottom: 0;
  color: $color-white;
}

h5 {
  font-family: "Montserrat Medium" ;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-top: 0;
  margin-bottom: 0;
}

h6 {
  font-family: "Montserrat Italic" ;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-top: 0;
  margin-bottom: 0;
}
h7 {
  font-family: "Montserrat Italic" ;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-top: 0;
  margin-bottom: 0;
}


p {
  font-family: "Alte Haas Grotesk Regular" ;
  font-weight: 50;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 0;
  margin-bottom: 0;
}