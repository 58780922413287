@import "./Constants.scss";

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 1;
  transition: background-color 0.3s ease;
  // border-bottom: 2px solid $color-black;

  &.scrolled {
    background-color: $color-nav; /* Change to whatever color you like */
  }
}


.horizontal-content-container {
  display: flex;
  align-items: center;
  padding-left: $spacer-2;
  padding-right: $spacer-2;
  padding-top: $spacer-1;
  padding-bottom: $spacer-1;
}

.vertical-content-container {
  // display: flex;
  // align-items: center;
  padding-top: $spacer-1;
  padding-bottom: $spacer-1;
  // height: 100%
  color: $color-blog;
}

.horizontal-links-container {
  display: flex;
  flex: 1;
  gap: $spacer-2;
}

.horizontal-links-container p {
    padding: 1rem;
    border-radius: 1.5rem;
    transition: 0.4s all ease;
  
    &:hover {
      background-color: rgba(248, 249, 251, 0.2);
    }
}

.vertical-links-container {
  display: flex;
  gap: $spacer-1;
}

.navbar-logo-container {
  display: flex;
  justify-content: center;
  color: $color-blog;
}

#navbar-pages {
  justify-content: flex-end;
}

#navbar-socials {
  justify-content: flex-end;
}

a {
  text-decoration: none;
  color: $color-white;
  font-size: 2.4rem;
  font-weight: lighter !important;
}


.bm-burger-bars {
  display: flex;
  background: $color-white;
}

.bm-burger-button {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  left: $spacer-2;
  top: $spacer-2;
}

#burger-menu {
  background: $color-black;
  padding-left: $spacer-1;
  padding-top: $spacer-1;
  height: 100vh;
  width: 80%;
  top: 0;
  position: -webkit-sticky;
  position: sticky;

}

.side-panel {
  height: 100%;
}

.bm-overlay {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0;
}

.bm-item {
  padding-bottom: $spacer-1;
}

.vertical-socials {
  padding-right: $spacer-1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $color-white;
}