@import "./Constants.scss";

.issue-container {
  margin-bottom: $spacer-3;
}

.issue-image-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.issue-description-layer {
  position: absolute;
  padding-left: $spacer-2;
  padding-right: $spacer-2;
  opacity: 0;
  display:inline-block;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2
}

.issue-container:hover .issue-image{
  opacity: 25%;
}

.issue-container:hover .issue-description-layer {
  visibility: visible;
  opacity: 1;
}

.issue-image {
  width: 70%;
}

.issue-description {
  padding: $spacer-1;
  position: relative;
  overflow:auto;
  height: 25rem;
}

