$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$color-black: #000000;
$color-white: #F1EFE8;;
$color-green: #afb287;
$color-issues: #F1EFE8;;
$color-blog: #292929;
$color-nav: #212121;
$color-green-transparent: rgba($color-green, 0.75);
$color-dark-green: #264e45;
$color-blog-wt: #FFFFFF;
$color-blog-blk: #292929;

$spacer-base: 1rem;
$spacer-1: $spacer-base * 2;
$spacer-2: $spacer-1 * 2;
$spacer-3: $spacer-2 * 2;
$spacer-4: $spacer-1 * 5;