@import "./Constants.scss";

.img {
	height: 25rem;
  border-radius: 2rem;
}

.img-container {
	text-align: center;
	
}