@import "./Constants.scss";

* {
    box-sizing: border-box;
}

.events-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: $color-blog;
    margin-bottom: $spacer-3;
    margin-top: 3%;
}

.event-header {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: $spacer-1;
    margin-bottom: 2rem;
    padding: 0 5%; /* Adjusts padding to align with blogs-container */
    flex-direction: column;
}

.event-landing {
    font-size: 4.5rem;
    color: #ffffff;
    font-weight: 100 !important;
    white-space: initial;
    line-height: 1;
}

.event-button {
    display: none;
    font-size: 18px;
    font-weight: 50 !important;
    padding: 1rem 0rem;
    margin-top: 2.5rem;
    border-radius: 1rem;
    border: 0.5px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    transition: all 0.5s ease;
    width: auto; /* Prevents full-width stretching */
    max-width: 148px; /* Optional: Limit button width */
    padding-left: 12px;
    padding-right: 14px;
    &:hover {
        cursor: pointer;
        color: black;
        background-color: white;
    }
}

.event-arrow {
    margin-left: 12px;
    transform: rotate(90deg);
    padding: 0;
    margin-right: 0;
}

.event-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 20px;
    padding-left: 7.75%; /* Matches the blogs-container padding */
    padding: 0 7.75%;
}

.images-container {
    max-width: 250px; /* Adjust as needed */
    width: auto;
    height: auto;
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 15px rgba(249, 248, 248, 0.3);
    }
}

.images-container img {
    width: 100%;
    height: auto;
}

.event-details {
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 1.5rem;
    padding-top: 16rem;
    color: white;
    line-height: 1;
}

.event-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.event-description {
    font-size: 14px;
    color: #ccc;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
}

.event-when {
    margin-bottom: 17rem;
    text-align: right;
    color: white;
}

.event-location {
    font-size: 18px;
    white-space: nowrap;
    text-decoration: bold !important;
}

.event-date {
    display: block;
    font-size: 18px;
}

.event-time {
    font-size: 18px;
}

@media (max-width: 970px) {
    .event-details {
        padding-top: 20.5rem;
    }

    .event-when {
        margin-bottom: 24rem;
    }
}


@media (max-width: 768px) {
    .events-container {
        margin-bottom: $spacer-1;
    }

    .event-header {
        text-align: center; /* Centers text */
        align-items: center; /* Ensures flex children are centered */
        padding: 0 5%; /* Adjusts side spacing */
    }

    .event-landing {
        font-size: 36px; /* Reduce font size on mobile */
        white-space: initial; /* Ensure text wraps on mobile */
        word-break: break-word;
    }

    .event-button {
        padding: 1rem 1.5rem;
    }

    .event-card {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center the cards */
        justify-content: center; /* Center within container */
        padding: 0 5%; /* Ensure spacing on both sides */
    }

    .event-details {
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
        padding: 0;
        text-align: center;
        max-width: 250px;
    }

    .event-title {
        font-size: 25px;
    }

    .event-description {
        margin-top: 1rem;
    }

    .event-when {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 0;
        text-align: center;
        color: white;
        align-items: center;
        justify-content: center;
    }
    
    .event-location {
        text-align: center;
        font-size: 2rem;
        white-space: nowrap;
    }
    
    .event-date {
        font-size: 1.8rem;
    }
    
    .event-time {
        font-size: 1.8rem;
    }
}
