@import "./Constants.scss";

.issue-container {
  margin-top: $spacer-2;
  margin-bottom: $spacer-2;
  color: $color-blog-wt
}

.issue-img-container {
  display: flex;
}

#large-screen-container {
  justify-content: flex-end;
}

#small-screen-container {
  justify-content: center;
}

.issue-img {
  width: 75%;
}

#small-screen {
  width: 50%;
}

.small-screen-row {
  margin-bottom: $spacer-2;
}

.issue-title {
  margin-bottom: $spacer-1;
}

.small-screen-container {
  padding-left: $spacer-2;
  padding-right: $spacer-2;
}

p {
  padding: 0;
}
