@import "./Constants.scss";

* {
    box-sizing: border-box;
}

.blog-container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: $spacer-4;
    padding: 2rem;
    background-color: $color-blog;
}

.header {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: $spacer-3;
    margin-bottom: 2rem;
    padding: 0 5%; /* Adjusts padding to align with blogs-container */
    flex-direction: column;
}

.landing {
    font-size: 4.5rem;
    color: #ffffff;
    font-weight: 100 !important;
    white-space: initial;
    line-height: 1;
}

.linkage {
    display: inline-block;
    font-size: 18px;
    font-weight: 50 !important;
    padding: 1rem 0rem;
    margin-top: 2.5rem;
    border-radius: 1rem;
    border: 0.5px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    transition: all 0.5s ease;
    width: auto; /* Prevents full-width stretching */
    max-width: 148px; /* Optional: Limit button width */
    padding-left: 12px;
    padding-right: 14px;
    &:hover {
        cursor: pointer;
        color: black;
        background-color: white;
    }
}

.arrow-icon {
    margin-left: 12px;
    transform: rotate(90deg);
    padding: 0;
    margin-right: 0;
}

.blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 7.75%; /* Matches the blogs-container padding */
    gap: 5.5rem;
    padding: 0 5.75%; /* Adds responsive spacing */
}

.blog-card {
    margin-top: 1.3rem;
    margin-bottom: $spacer-1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: auto;
    max-width: 400px;
    flex-grow: 1;
}

.image-container {
    background-size: cover;
    background-position: center;
    width: 100%; 
    margin: 0; 
    padding: 0; 
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;

    &:hover {
        box-shadow: 0px 0px 15px rgba(249, 248, 248, 0.3);
    }
}

.image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blog-details {
    flex-grow: 1; /* Makes all blog content take up the same space */
    display: flex;
    flex-direction: column;
    padding: 0rem;
}

.blog-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: lighter !important;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2; /* Adjust line height to add space */
}

.blog-author {
    color: #545454;
    font-size: 15px;
}

@media (max-width: 768px) {
    .header {
        text-align: center; /* Centers text */
        align-items: center; /* Ensures flex children are centered */
        padding: 0 5%; /* Adjusts side spacing */
    }

    .landing {
        font-size: 36px; /* Reduce font size on mobile */
        white-space: initial; /* Ensure text wraps on mobile */
        word-break: break-word;
    }

    .linkage {
        padding: 1rem 1.5rem;
    }


    .blogs-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center the cards */
        justify-content: center; /* Center within container */
        padding: 0 5%; /* Ensure spacing on both sides */
        gap: 1rem;
    }

    .blog-card {
        width: 70%; /* Ensures consistent width for each card */
        max-width: 400px; /* Prevents it from getting too large */
    }

    .blog-details {
        text-align: center;
    }

    .blog-author {
        text-align: center;
    }
}

@media (max-width: 1500px) {
    .blog-card {
        max-width: 350px;
    }
}
