@import "./Constants.scss";

.issues-page {
  margin-top: 10%;
}

.issues-grid-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.issues {
  margin-top: $spacer-2;
}