@import "./Constants.scss";

.featured-issues-img-container {
  position: relative; // Ensure the container is a positioned parent
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .who-we-are-img {
    margin-top: 5%;
    width: 85%; // Ensure the image fills the container
    height: auto;
    display: block;
    box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.2);
  }
  
}

.featured-issue-container {
  top: 0;
  left: 0;
  right: 0;
  padding-left: $spacer-2;
  padding-right: $spacer-2;
  padding-top: $spacer-1;
  padding-bottom: $spacer-1;
  background-color: $color-blog-blk;
  height: auto;
}

.featured-issue-title {
  margin-top: $spacer-1;
  color: $color-blog-wt;
}

h2, p {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: $color-blog-wt;
}

.title-container {
  .featured-issue-title {
    font-size: 4.5rem; /* Adjust size as needed */
    margin: 0; /* Remove any default margins */
    text-align: left; /* Ensure the text aligns left */
    padding: 1.15rem; /* Add padding for spacing from the edges */
  }
}

.featured-issue-title {
  position: absolute;
  top: 5rem;
  left: 0;
}

.explore-button {
  margin-top: 12.5rem; /* Space above the button */
  padding: 1rem 0rem; /* Adjust button size */
  color: $color-blog-wt; /* Replace with your desired text color */
  border: 0.5px solid $color-blog-wt;
  border-radius: 1rem; /* Optional: Rounded corners */
  font-size: 18px; /* Button font size */
  font-weight: 50 !important;
  cursor: pointer; /* Change cursor to pointer on hover */
  background: $color-blog-blk;
  transition: all 0.5s ease;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 154px;
  width: auto;
  font-family: "Alte Haas Grotesk Regular" ;
}

.explore-button:hover {
  background: $color-blog-wt; /* Swap background */
  color: $color-blog-blk; /* Swap text color */
  border: 1px solid $color-blog-wt;
  cursor: pointer;
}

.featured-issue-description {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.arrow-icon {
  margin-left: 12px;
  transform: rotate(90deg);
  padding: 0;
  margin-right: 0;
}

@media (max-width: 768px) {
  .title-container {
      text-align: center; /* Centers text */
      align-items: center; /* Ensures flex children are centered */
      padding: 0 5%; /* Adjusts side spacing */

      .featured-issue-title {
        position: relative;
        text-align: center; /* Centers text */
        align-items: center; /* Ensures flex children are centered */
        padding: 0 5%; /* Adjusts side spacing */
        white-space: initial; /* Ensure text wraps on mobile */
        word-break: break-word;
        line-height: 0.9;
        margin-top: -2%;
      }
  }

  .explore-button {
    margin-top: 8rem;
  }

}
